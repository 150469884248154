"use strict";

export function initInScope($scope) {
    $scope.find(".js-load-video");
    loadVideo();
}

function loadVideo() {
    const isDesktop = window.matchMedia('(min-width: 768px)');
    let videos = document.getElementsByClassName("js-load-video");
    Array.from(videos).forEach(function(videoEl) {
        let src = isDesktop.matches ? videoEl.dataset.desktopSrc : videoEl.dataset.mobileSrc;
        let poster = isDesktop.matches ? videoEl.dataset.desktopPoster : videoEl.dataset.mobilePoster;
        videoEl.src = src;
        videoEl.poster = poster;
    });
}
