"use strict";

import $ from 'jquery';
import * as smoothScroll from "@elements/smooth-scroll";
import * as scrollspy from "bootstrap/js/src/scrollspy";

const defaultSelectors = {
    list: '.js-anchor-nav',
    listItem: '.js-anchor-nav__item'
};

const defaultOptions = {
    renderItem: function (text, icon, anchor) {
        return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link" href="#' + anchor + '"><span>' + text + '</span></a></li>';
    },
    smoothScroll: false,
    scrollSpy: false,
    scrollSpyTarget: '',
    scrollSpyOffset: 10,
    scrollSpyMethod: 'auto'
};

let options;

export function createInitInScope(selectors = defaultSelectors, customOptions = {} ) {

    return function ($scope) {

        options = {
            ...defaultOptions,
            ...customOptions,
        };

        if(options.scrollSpy){
            $('body').scrollspy({
                target: options.scrollSpyTarget,
                offset: options.scrollSpyOffset,
                method: options.scrollSpyMethod
            });
        }

        let $list = $(selectors.list),
            $listItem = $(selectors.listItem);
       // console.log($list, '$list');
        //console.log($listItem, '$listItem');
        $listItem.each(function () {
            let $this = $(this),
                title = $this.data('anchor-nav-title'),
                icon = $this.data('anchor-nav-icon') || '' || undefined,
                titleForAnchor = title.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '').toLowerCase();

            if($this.attr('id')) {
                addNavItem($list,title,icon,$this.attr('id'));
            } else {
                $this.attr('id', titleForAnchor);
                addNavItem($list,title,icon,titleForAnchor);
            }

        });

        if(options.smoothScroll) {
            smoothScroll.initInScope();
        }

        if(options.scrollSpy) {
            $('body').scrollspy('refresh');
        }

    }
}

function addNavItem($list, text, icon, anchor) {
    if($list.find(`[href='#${anchor}']`).length < 1) {
        $list.append(options.renderItem(text, icon, anchor))
    }
}

export const initInScope = createInitInScope();

