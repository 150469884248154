"use strict";

import $ from 'jquery'
import 'slick-carousel';
import {loadImg} from "@elements/lazy-img";
import {onEnterViewPort} from "@elements/viewport-utils";
import {getPrefixedDataSet} from "@elements/data-set-utils"
import * as trackingGa4 from './tracking-ga4';
import initModulesInScope from '@elements/init-modules-in-scope';


export function initInScope($scope) {
    let $sliderElements = $scope.find('.js-product-slider');
    $sliderElements.each(function () {
        let $slider = $(this);
        let slideToShow = $slider.data('slider-slide-to-show');

        let sliderOptions;

        if (matchMedia('(min-width: 1200px)').matches) {
            sliderOptions = {
                nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow slider__arrow--light slider__arrow--small" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
                prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow slider__arrow--light slider__arrow--small" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
                infinite: false,
                slidesToShow: slideToShow || 4,
                slidesToScroll: 1,
                centerMode: false,
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 4.02
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            arrows:false,
                            slidesToShow: 2.2
                        }
                    },
                ]
            };
        }else if(matchMedia('(min-width: 768px)').matches){
            sliderOptions = {
                nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow slider__arrow--light slider__arrow--small" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
                prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow slider__arrow--light slider__arrow--small" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
                infinite: false,
                slidesToShow: 4.02,
                slidesToScroll: 1,
                centerMode: false
            };
        }else if(matchMedia('(max-width: 767px)').matches){
            sliderOptions = {
                infinite: false,
                slidesToScroll: 1,
                arrows:false,
                slidesToShow: 1.3
            };
        }



        const $tabPaneParent = $slider.closest('.tab-pane:not(.active)');
        const $collapseParent = $slider.closest('.collapse');
        const $modalParent = $slider.closest('.modal');

        if ($tabPaneParent && $tabPaneParent.length) {
            /*wait for the initializiation of the slider if it is hidden in a tab */
            const $tabTrigger = $('[href="#' + $tabPaneParent.attr('id') + '"],[data-toggle=tab][data-target="#' + $tabPaneParent.attr('id') + '"]');

            $tabTrigger.one('shown.bs.tab', function () {
                initSlider($slider, sliderOptions);
            });

        } else if ($collapseParent && $collapseParent.length) {
            /* wait until slide is shown if it is initially hidden in a collapse/accordion */
            $collapseParent.one('shown.bs.collapse', function () {
                initSlider($slider, sliderOptions);
            });

        } else if ($modalParent && $modalParent.length) {
            /* wait until slide is shown if it is initially  hidden in a modal */
            $modalParent.one('shown.bs.modal', function () {
                initSlider($slider, sliderOptions);
            });

        } else {
            // no tab, accordion, modal -> init slider as usual
            initSlider($slider, sliderOptions);
        }
    });
}

export function initSlider($slider, sliderOptions) {
    $slider.slick(sliderOptions).on('afterChange', function (event, slick, currentSlide) {
        preloadNSlide(slick, currentSlide + 1, sliderOptions.slidesToShow);
    });

    onEnterViewPort($slider, function ($slider) {
        preloadNSlide($slider.slick('getSlick'), 1, sliderOptions.slidesToShow);
    });
    return $slider;
}

export function preloadSlide(slick, slideIndex) {
    let slideToPreload = slick.$slides[slideIndex];

    if (slideToPreload) {
        let $imgToPreload = $(slideToPreload).find('.js-lazy-img');
        if ($imgToPreload && $imgToPreload.length) {
            loadImg($imgToPreload);
        }
    }
}

export function preloadNSlide(slick, start, n) {
    for (let i = 0; i < n; i++) {
        preloadSlide(slick, start + n);
    }
}
