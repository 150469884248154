import './scripts/common';
import onInitModulesInScope from "./scripts/onInitModulesInScope";

if (!window.HTMLPictureElement) {
    import('picturefill');
}


let _config = window['_config'] || {};

let app = window['app'] || {
    debug: false,
};

window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('./scripts/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as slider from '@elements/slider';
if(matchMedia('(max-width: 767px)').matches) {
    onInitModulesInScope(slider.createInitInScope('.js-teaser-sujet-slider', {
        infinite: false,
        slidesToShow: 1.4,
        slidesToScroll: 1,
        arrows: false,
    }));
}

import * as loadVideo from './scripts/load-video';
onInitModulesInScope(loadVideo.initInScope);

import * as animationMoveUp from './scripts/animation-move-up';
onInitModulesInScope(animationMoveUp.initInScope);

import * as animationFadeIn from './scripts/animation-fade-in';
onInitModulesInScope(animationFadeIn.initInScope);

import * as teaserSlider  from './scripts/teaserSlider';
onInitModulesInScope(teaserSlider.initInScope);

import * as productSlider from './scripts/product-slider';
onInitModulesInScope(productSlider.initInScope);

import * as anchorNav from './scripts/anchor-nav';
onInitModulesInScope(anchorNav.createInitInScope({
    list: '.js-anchor-nav',
    listItem: '.js-anchor-nav__item'
},  {
    renderItem: function (text, anchor, icon) {
        return '<li class="nav-item anchor-nav__item list-inline-item"><a class="nav-link js-smoothscroll__link" href="#' + anchor + '"><span class="anchor-nav__title">' + text + '</span></a></li>';
    },
    smoothScroll: true,
    scrollSpy: true,
    scrollSpyOffset: 100
}));

onInitModulesInScope(anchorNav.createInitInScope({
    list: '.js-content-anchor-nav__list',
    listItem: '.js-content-anchor-nav__item'
},  {
    renderItem: function (text, icon, anchor) {
        return '<li class="nav-item anchor-nav__item content-anchor-nav__item "><a class="d-flex flex-column js-smoothscroll__link" href="#' + anchor + '"><span class="icon icon-' + icon + ' content-anchor-nav__item-icon mb-2"></span><span class="btn-link-underline content-anchor-nav__item-title">' + text + '</span></a></li>';
    },
    smoothScroll: true,
    scrollSpy: false,
    scrollSpyTarget: '',
    scrollSpyOffset: 10,
    scrollSpyMethod: 'auto'

}));


// check if user reached bottom
$(window).scroll(function() {
    if($(window).scrollTop() + $(window).height() > $(document).height() - 100) {
        $('body').addClass('is-bottom');
    }else{
        if($('body').hasClass('is-bottom')){
            $('body').removeClass('is-bottom');
        }
    }
});

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})(jQuery);
