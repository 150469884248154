"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-img-split-teaser__slider');

    for (let i = 0; i < $slider.length; i++) {
        if ( $slider.eq(i).find('.js-img-split-teaser__slider-item').length >= 3 || matchMedia('(min-width: 768px)').matches) {

            import('slick-carousel').then(function () {
                $slider.eq(i).slick({
                    slidesToShow: 2.1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false,
                    infinite: false,
                    nextArrow: '<button type="button" class="btn-no-styling slick-next slider__arrow slider__arrow--light slider__arrow--small slider__arrow--next" aria-label="Next"><span class="slider__arrow__icon icon icon-arrow-right"></span></button>',
                    prevArrow: '<button type="button" class="btn-no-styling slick-prev slider__arrow slider__arrow--light slider__arrow--small slider__arrow--prev" aria-label="Previous"><span class="slider__arrow__icon icon icon-arrow-left"></span></button>',
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1.2,
                                slidesToScroll: 1,
                            }
                        }
                    ]
                });
            });
        }
    }
}
